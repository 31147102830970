<div class="wrapper">
    <div class="wrapper__content">
      <div class="event-bloc">
         <div class="event-bloc__img">
            <a href="https://chronica-shop.be/collections/small-bands/products/small-bands-nicolas-de-wolf-tome-1" target="_blank">
               <img src="assets/images/global/bd-achat.png" alt="BD">
            </a>
         </div>
         <div class="event-bloc__text">
            <div class="event-bloc__text__content">
               <h2>La bande dessinée est sortie!</h2>
                  <p>Vous la trouverez peu à peu dans les magasins et les librairies de Belgique. Il est également possible de l'acheter en ligne sur le site des Éditions Chronica !</p>
            </div>
            <div class="event-bloc__text__buttons">
               <div class="event-bloc__text__buttons--btn">
                  <a class="btn btn--turq" href="https://chronica-shop.be/collections/small-bands/products/small-bands-nicolas-de-wolf-tome-1" target="_blank">J'achète ma BD</a>
               </div>
            </div>
         </div>
      </div>  
       <div class="content-bloc">
          <div class="content-bloc__header">
            <svg width="147" height="89" viewBox="0 0 147 89" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M39.7617 22.25C39.7617 24.3102 39.1436 25.9583 38.1135 27.1944C37.0834 28.2245 35.6413 28.8426 33.7871 28.8426C32.139 28.8426 30.6968 28.2245 29.4607 26.9884C28.2246 25.5463 27.6066 23.8981 27.6066 21.6319C27.6066 21.2199 27.6066 20.6019 27.6066 19.7778H32.345V21.4259C32.345 23.0741 32.757 23.8981 33.7871 23.8981C34.6112 23.8981 34.8172 23.2801 35.0232 22.25V20.1898C35.0232 19.5718 34.4052 18.7477 33.1691 17.5116C31.1089 15.4514 29.8728 14.2153 29.6668 14.0093C28.4306 12.5671 27.8126 11.331 27.8126 10.0949V7.21065C27.8126 5.15046 28.2246 3.2963 29.2547 2.06019C30.2848 0.618056 31.933 0 33.9931 0C35.6413 0 37.0834 0.618055 38.1135 1.85417C39.1436 2.88426 39.5556 4.32639 39.5556 6.18056V9.68287H34.8172C34.8172 9.06481 34.8172 8.24074 34.8172 6.79861C34.8172 6.38657 34.8172 5.76852 34.6112 5.35648C34.4052 4.94444 34.1992 4.73843 33.7871 4.73843C32.963 4.73843 32.345 5.5625 32.345 7.00463V8.8588C32.345 9.68287 32.9631 10.5069 34.1992 11.9491C35.4353 13.1852 36.4654 14.4213 37.7015 15.6574C38.9376 17.0995 39.5556 18.5417 39.5556 19.7778V22.25H39.7617Z" fill="white"/>
               <path d="M59.9514 27.6065C59.9514 28.0185 59.9514 28.2246 59.9514 28.4306L54.5949 28.0185C54.5949 27.4005 54.5949 26.3704 54.5949 24.7222C54.5949 23.6922 54.5949 22.25 54.5949 20.6019C54.5949 18.9537 54.5949 18.1297 54.5949 18.1297C54.5949 17.7176 54.5949 17.3056 54.3889 17.3056C54.1829 17.3056 54.1829 17.5116 53.9769 17.9236C53.7709 18.9537 53.5648 20.3959 53.3588 22.6621C53.1528 25.3403 52.9468 26.9884 52.7408 27.6065H48.6204C48.4144 26.5764 48.2084 24.7222 47.7963 22.044C47.5903 19.5718 47.3843 18.1297 47.1783 17.9236C46.9723 17.5116 46.9723 17.3056 46.7662 17.3056C46.7662 17.3056 46.5602 17.5116 46.5602 18.1297C46.5602 23.6922 46.5602 26.9884 46.5602 27.8125L41.4098 27.6065C41.4098 26.1644 41.2037 24.3102 41.2037 21.632C41.2037 18.7477 41.2037 14.4213 41.4098 8.85882C41.6158 4.12039 41.6158 1.44215 41.6158 1.03012C41.6158 0.824097 43.2639 0.824097 46.5602 1.03012C46.7662 1.85419 48.0023 5.3565 50.0625 11.9491C50.2685 12.5672 50.4746 12.7732 50.4746 12.7732C50.6806 12.7732 50.6806 12.5672 50.8866 11.9491C51.7107 9.27086 52.9468 5.56252 54.1829 0.824097L59.1273 1.03012C59.1273 5.15049 59.3334 9.06484 59.3334 13.1852C59.9514 19.9838 59.9514 24.7222 59.9514 27.6065Z" fill="white"/>
               <path d="M75.4028 28.8426H70.4583L70.0463 26.5764C69.8403 25.3403 69.6342 24.5162 69.4282 23.6921C69.4282 23.4861 69.2222 23.4861 69.0162 23.4861H67.9861C67.7801 23.4861 67.7801 23.4861 67.5741 23.6921C67.368 25.1342 67.162 26.7824 66.75 28.6365L61.8055 28.4305C62.6296 23.6921 63.4537 18.9537 64.2778 14.0092L67.162 0.205994C68.3981 0.205994 69.6342 0.205994 70.8704 0.205994L73.5486 14.0092C73.9606 18.9537 74.7847 23.8981 75.4028 28.8426ZM69.4282 18.9537C69.0162 16.2754 68.8102 15.0393 68.8102 15.0393C68.6042 15.0393 68.6042 15.6574 68.3981 16.6875C68.3981 17.5115 68.1921 18.1296 68.1921 18.9537C68.1921 18.9537 68.1921 19.1597 68.6042 19.1597C68.8102 19.1597 69.0162 19.1597 69.2222 19.1597C69.2222 19.1597 69.4282 19.1597 69.4282 18.9537Z" fill="white"/>
               <path d="M86.3218 28.6365C86.5278 28.6365 84.8797 28.6365 81.7894 28.6365C79.7292 28.6365 78.4931 28.6365 77.669 28.6365C77.257 28.6365 77.051 28.2245 77.051 27.8124C76.845 15.8634 76.845 7.00458 76.845 1.03004C80.1413 0.824019 81.7894 1.03004 81.7894 1.23606C81.7894 2.26615 81.7894 5.35643 81.7894 10.7129C81.7894 16.4814 81.7894 20.3958 81.7894 22.662C81.7894 23.28 81.9954 23.4861 82.6135 23.4861H86.3218V28.6365Z" fill="white"/>
               <path d="M97.2407 28.6365C97.4467 28.6365 95.7985 28.6365 92.7082 28.6365C90.6481 28.6365 89.4119 28.6365 88.5879 28.6365C88.1758 28.6365 87.9698 28.2245 87.9698 27.8124C87.7638 15.8634 87.7638 7.00458 87.7638 1.03004C91.0601 0.824019 92.7082 1.03004 92.7082 1.23606C92.7082 2.26615 92.7082 5.35643 92.7082 10.7129C92.7082 16.4814 92.7082 20.3958 92.7082 22.662C92.7082 23.28 92.9143 23.4861 93.5323 23.4861H97.2407V28.6365Z" fill="white"/>
               <path d="M22.25 81.5833C22.25 84.4676 20.8079 86.7338 17.9236 87.9699H0V37.2893L12.1551 37.0833C14.6273 37.0833 16.8935 38.1134 18.7477 39.9676C20.6019 41.8217 21.4259 44.0879 21.4259 46.5602V53.1528C21.4259 55.625 21.2199 57.4791 20.6019 58.5092C19.9838 59.9514 18.7477 61.3935 16.6875 62.4236C18.1296 63.0416 19.3657 64.2778 20.3958 65.7199C21.6319 67.368 22.25 69.0162 22.25 70.8704V81.5833V81.5833ZM12.7731 49.6504C12.7731 48.2083 12.7731 47.1782 12.3611 46.5602C11.9491 45.7361 11.125 45.3241 9.88889 45.3241C9.27083 45.3241 8.65278 45.3241 8.44676 45.3241C8.24074 45.3241 8.03472 45.5301 8.03472 45.9421C8.03472 48.0023 8.03472 51.9166 8.03472 57.4791C8.03472 58.0972 8.24074 58.3032 8.44676 58.3032C9.27083 58.3032 10.0949 58.0972 11.125 57.6852C12.1551 57.0671 12.5671 56.4491 12.5671 55.419L12.7731 49.6504ZM13.1852 75.1967V71.4884C13.1852 69.0162 11.7431 67.9861 8.8588 67.9861C8.65278 67.9861 8.44676 68.1921 8.44676 68.6041V79.9352C8.44676 80.5532 8.65278 80.7592 9.06482 80.7592H9.88889C12.1551 80.5532 13.1852 78.6991 13.1852 75.1967Z" fill="white"/>
               <path d="M50.0626 89.0001L41.2038 88.794L40.5857 84.6737C40.1737 82.6135 39.7616 80.9653 39.5556 79.3172C39.5556 79.1112 39.3496 78.9051 38.9376 78.9051H36.4653C36.2593 78.9051 36.0533 79.1112 35.8473 79.3172C35.4352 81.7894 35.0232 84.6737 34.4051 87.97L25.3403 87.7639C26.7825 79.3172 28.2246 70.6644 29.6667 62.0116L34.6112 37.2894C36.8774 37.0834 39.1436 37.0834 41.4098 37.2894L46.1482 62.2176C47.5903 71.2825 48.8264 80.1413 50.0626 89.0001ZM39.1436 71.2825C38.5255 66.544 38.1135 64.0718 37.9075 64.0718C37.7014 64.0718 37.4954 65.1019 37.0834 67.1621C36.8774 68.6042 36.6713 69.8403 36.6713 71.2825C36.6713 71.4885 36.8774 71.4885 37.2894 71.4885C37.7014 71.4885 38.1135 71.4885 38.5255 71.4885C38.9376 71.4885 39.1436 71.4885 39.1436 71.2825Z" fill="white"/>
               <path d="M76.0209 39.1436L75.6088 87.1459L66.75 87.5579C65.3079 81.3774 63.6598 74.1667 62.0116 66.132C61.8056 65.5139 61.5996 65.1019 61.1875 65.1019C60.9815 65.1019 60.7755 65.5139 60.7755 66.338C60.7755 67.1621 60.7755 70.2524 60.7755 75.1968C60.7755 80.1413 60.7755 84.0556 60.7755 86.9399L52.5348 87.1459C52.3287 81.3774 52.3287 72.5186 52.3287 60.5695C52.3287 49.2385 52.5348 41.8218 52.7408 38.7315L62.2176 38.5255C64.2778 48.6204 65.7199 55.213 66.544 58.0973C66.956 59.1274 67.1621 59.7454 67.3681 59.7454C67.5741 59.7454 67.7801 59.1274 67.7801 57.8913C67.5741 54.595 67.5741 48.0024 67.5741 38.5255H76.0209V39.1436Z" fill="white"/>
               <path d="M99.3008 71.2825C99.3008 83.0255 95.5925 88.588 87.9698 88.176H78.905L78.699 38.5255V37.7014C80.5531 37.4954 84.0555 37.4954 88.7939 37.4954C92.5022 37.4954 95.1805 39.3496 97.0346 43.0579C98.4767 45.9422 99.0948 49.6505 99.0948 54.3889V71.2825H99.3008ZM91.2661 68.8102V53.1528C91.2661 51.2987 90.8541 49.6505 89.824 48.2084C88.7939 46.7663 87.7638 45.9422 86.7337 45.9422C86.3217 45.9422 86.3217 46.1482 86.3217 46.7663C86.3217 46.9723 86.3217 51.0926 86.3217 59.3334C86.3217 67.5741 86.3217 74.1667 86.3217 79.1112C86.3217 79.7292 86.5277 80.1413 86.7337 80.1413C87.7638 80.1413 88.7939 79.1112 89.618 76.6389C90.648 74.3727 91.2661 71.6945 91.2661 68.8102Z" fill="white"/>
               <path d="M122.787 77.0509C122.787 80.7592 121.757 83.6435 119.903 85.7037C118.049 87.7638 115.37 89 111.868 89C108.984 89 106.306 87.7638 104.245 85.4976C101.979 83.0254 100.949 79.7291 100.949 75.8148C100.949 75.1967 100.949 73.9606 100.949 72.5185H109.396V75.6088C109.396 78.699 110.22 80.1412 111.868 80.1412C113.104 80.1412 113.928 79.1111 113.928 77.0509L114.134 73.5486C114.134 72.5185 113.104 70.8703 110.838 68.8101C107.13 65.1018 105.069 63.0416 104.657 62.6296C102.391 59.9513 101.361 57.6851 101.361 55.625V50.2685C101.361 46.5601 102.185 43.4699 104.039 41.2037C105.894 38.7314 108.778 37.4953 112.486 37.2893C115.576 37.2893 117.843 38.3194 119.697 40.3796C121.345 42.4398 122.169 45.118 122.169 48.4143V54.5949H113.722C113.722 53.5648 113.722 51.9166 113.928 49.6504C113.928 48.8263 113.722 48.0023 113.516 46.9722C113.104 46.1481 112.692 45.7361 112.074 45.7361C110.426 45.7361 109.602 47.1782 109.602 49.8564V53.1527C109.602 54.3888 110.632 56.243 112.898 58.7152C114.958 60.9814 117.019 63.2476 119.079 65.3078C121.345 67.9861 122.375 70.4583 122.375 72.7245V77.0509H122.787Z" fill="white"/>
               <path d="M146.067 45.1181L145.861 58.5093C145.861 61.1876 145.037 63.6598 143.595 65.5139C142.359 67.1621 141.123 67.9862 139.887 67.9862C139.475 67.9862 139.269 68.3982 139.269 69.0163C139.269 69.2223 139.269 69.8403 139.269 71.0764C139.269 72.1065 139.269 72.7246 139.269 73.3426C139.269 73.7547 137.002 73.9607 132.47 73.5487C132.47 72.7246 132.47 68.8102 132.47 62.0116C132.47 61.3936 132.676 60.9815 133.294 60.9815C134.118 60.7755 134.53 60.5695 134.942 60.5695C135.56 60.3635 136.178 59.9514 136.796 59.3334C137.621 58.5093 138.033 54.801 138.033 48.6204C138.033 46.3542 137.208 45.3241 135.56 45.3241C133.912 45.3241 132.882 46.3542 132.882 48.6204L132.676 52.3288C127.732 52.5348 125.259 52.7408 125.259 52.3288C125.259 51.9167 125.259 50.2686 125.465 47.1783C125.465 43.676 126.496 41.2038 128.144 39.7616C129.792 38.5255 132.47 37.9075 136.178 37.9075C138.857 37.9075 141.329 38.5255 143.183 39.9677C145.037 41.2038 146.067 43.0579 146.067 45.1181ZM139.681 88.382C134.53 88.588 131.852 88.382 131.852 87.7639C131.852 85.9098 131.852 83.4376 131.852 80.7593C131.852 80.3473 134.324 80.3473 139.681 80.9653C139.887 82.2014 139.887 84.8797 139.681 88.382Z" fill="#46B8AC"/>
               </svg>                           
          </div>
          <div class="content-bloc__content">
            <h1>Que raconte cette BD?</h1>

            <!-- <fa-icon [icon]="faCoffee" ></fa-icon>
            <fa-icon [icon]="faFilm" ></fa-icon>
            <fa-icon [icon]="faMusic" ></fa-icon> -->
            
            <p>Small Bands est <strong>une bande dessinée retraçant des histoires et des anecdotes de petits groupes de musique de tous les styles.</strong> Des situations absurdes qui leur arrivent tout au long de leur carrière... et je peux vous dire qu'elles sont nombreuses et qu'elles méritent d'être partagées!
               <strong>Découvrez ces histoires, racontées par les groupes eux-mêmes</strong> et mises en images dans 45 pages de BD. Chaque histoire vous redirigera vers le site de Small Bands où <strong>vous pourrez voir leurs témoignages et découvrir leur univers.</strong></p>
            <h1>Un soutien aux groupes de musique!</h1>
            <p>Le parcours des musiciens, compliqué et parfois injuste, n’est pas assez mis en avant. <strong>Cette BD a également comme but de soutenir tous ces groupes</strong>, les faire découvrir aux lecteurs au travers des dessins, témoignages et liens vers les plateformes de musique et réseaux sociaux.</p>
            <h1>Aperçu de la BD</h1>
            <div class="gallery">
               <img src="/assets/images/global/smallbands_cover_thumbnail.jpg" (click)="showGallery(0)">
               <img src="/assets/images/global/smokebomb-chump-1_thumbnail.png" (click)="showGallery(1)">
               <img src="/assets/images/global/smokebomb-chump-2_thumbnail.png" (click)="showGallery(2)">
               <img src="/assets/images/global/smokebomb-chump-3_thumbnail.png" (click)="showGallery(3)">
               <img src="/assets/images/global/smokebomb-chump-4_thumbnail.png" (click)="showGallery(4)">
           </div>
            <!-- <h1>Découvrez le trailer vidéo!</h1>
            <div class="embedresize">
               <div>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/DH29wANlU4Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
               </div>
            </div> -->
          </div>
       </div>
       <div class="content-bloc">
         <div class="content-bloc__content">
            <h1>Qui se cache derrière ce projet?</h1>
            <img class="picture_profil" src="assets/images/global/nicolas_de_wolf_smallbands.jpg" alt="Nicolas De Wolf">
            <p>Hohey! Je m’appelle Nicolas De Wolf. Je suis...</p>
            <p><strong>Web Designer</strong> la journée,<br/><strong>dessinateur</strong> en soirée,<br/>et <strong>musicien</strong> dans un groupe de Metal la nuit!</p>            
            <p><strong>Trois passions que j’ai tenté de combiner dans un seul et même projet: </strong></p>
            <p>Faire une BD sur le parcours fascinant des petits groupes de musique tout en permettant aux lecteurs de découvrir leur univers musical.</p>
         </div>
       </div>
       <div class="content-bloc">
         <div class="content-bloc__content">
            <h1>Les groupes sélectionnés pour le tome 1</h1>
            <div class="wrapper-band-home">
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/gbb']"><img src="assets/images/home/gbb-small-bands-thumbnail.jpg" alt="Gustave Brass Band"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/baraka']"><img src="assets/images/home/baraka-small-bands-thumbnail.jpg" alt="Baraka"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/yvespaquet']"><img src="assets/images/home/yves-paquet-small-bands-thumbnail.jpg" alt="Yves Paquet"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/skal']"><img src="assets/images/home/skal-small-bands-thumbnail.jpg" alt="Skal"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/bugulnoz']"><img src="assets/images/home/bugul-noz-small-bands-thumbnail.jpg" alt="Bugul Noz"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/gazigann']"><img src="assets/images/home/gazigann-small-bands-thumbnail.jpg" alt="Gazigann"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/shaarghot']"><img src="assets/images/home/shaarghot-small-bands-thumbnail.jpg" alt="Shaârghot"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/thomasfrankhopper']"><img src="assets/images/home/thomas-frank-hopper-small-bands-thumbnail.jpg" alt="Thomas Frank Hopper"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/versatile']"><img src="assets/images/home/versatile-small-bands-thumbnail.jpg" alt="Versatile"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/kidnoize']"><img src="assets/images/home/kidnoize-small-bands-thumbnail.jpg" alt="Kid Noize"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/lajungle']"><img src="assets/images/home/la-jungle-small-bands-thumbnail.jpg" alt="La Jungle"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/smokebomb']"><img src="assets/images/home/smokebomb-small-bands-thumbnail.jpg" alt="SmokeBomb"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/chump']"><img src="assets/images/home/chump-small-bands-thumbnail.jpg" alt="Chump"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/fleddymelculy']"><img src="assets/images/home/fleddymelculy-small-bands-thumbnail.jpg" alt="Fleddy Melculy"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/rudylenners']"><img src="assets/images/home/rudylenners-small-bands-thumbnail.jpg" alt="Rudy Lenners"></a>
               <a class="wrapper-band-home--thumbnail" [routerLink]="['/evyka']"><img src="assets/images/home/evyka-small-bands-thumbnail.jpg" alt="Evyka"></a>
               <!-- <div class="wrapper-band-home--thumbnail"><img class="groupe-home" src="assets/images/home/coming-soon.jpg" alt="coming soon"></div> -->
            </div>
         </div>
       </div>
       <div class="content-bloc">
         <iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/0iQdhFoeqUNgjwa5068PKn?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
       </div>
    </div>
    <div class="wrapper__background">
      <img src="assets/images/global/smallbands_gazigann_planche.png" alt="Small Bands planche Skal 2" class="bottom-right">
      <img src="assets/images/global/smallbands_gustave_brass_band_planche.png" alt="Small Bands cover" class="top-left">
    </div>
</div>