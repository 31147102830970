/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'concept',
        title: 'Accueil',
        type : 'basic',
        icon : 'heroicons_solid:home',
        link : '/concept'
    },
    {
        id      : 'groups',
        title   : 'Les groupes',
        //subtitle: 'With subtitle',
        icon    : 'heroicons_solid:music-note',
        type    : 'collapsable',
        children: [
            {
                id   : 'Skal',
                title: 'Skal',
                subtitle: 'Folk Metal',
                type : 'basic',
                link  : '/skal'
            },
            {
                id   : 'Gazigann',
                title: 'Gazigann',
                subtitle: 'Rock',
                type : 'basic',
                link  : '/gazigann'
            },
            {
                id   : 'Baraka',
                title: 'Baraka',
                subtitle: 'Fritcore',
                type : 'basic',
                link  : '/baraka'
            },
            {
                id   : 'Gbb',
                title: 'Gustave Brass Band',
                subtitle: 'Folk',
                type : 'basic',
                link  : '/gbb'
            },
            {
                id   : 'Bugulnoz',
                title: 'Bugul Noz',
                subtitle: 'Celtic Punk',
                type : 'basic',
                link  : '/bugulnoz'
            },
            {
                id   : 'Yvespaquet',
                title: 'Yves Paquet',
                subtitle: 'Pop/Soul',
                type : 'basic',
                link  : '/yvespaquet'
            },
            {
                id   : 'Shaarghot',
                title: 'Shaârghot',
                subtitle: 'Electro Metal Punk',
                type : 'basic',
                link  : '/shaarghot'
            },
            {
                id   : 'Thomasfrankhopper',
                title: 'Thomas Frank Hopper',
                subtitle: 'Rock blues',
                type : 'basic',
                link  : '/thomasfrankhopper'
            },
            {
                id   : 'Versatile',
                title: 'Versatile',
                subtitle: 'Black Metal',
                type : 'basic',
                link  : '/versatile'
            },
            {
                id   : 'Kidnoize',
                title: 'Kid Noize',
                subtitle: 'DJ Electropop',
                type : 'basic',
                link  : '/kidnoize'
            },
            {
                id   : 'Lajungle',
                title: 'La Jungle',
                subtitle: 'Techno/Transe Rock',
                type : 'basic',
                link  : '/lajungle'
            },
            {
                id   : 'Smokebomb',
                title: 'SmokeBomb',
                subtitle: 'Harcore Punk Hip Hop',
                type : 'basic',
                link  : '/smokebomb'
            },
            {
                id   : 'Chump',
                title: 'Chump',
                subtitle: 'Punk Rock',
                type : 'basic',
                link  : '/chump'
            },
            {
                id   : 'Fleddymelculy',
                title: 'Fleddy Melculy',
                subtitle: 'Metal',
                type : 'basic',
                link  : '/fleddymelculy'
            },
            {
                id   : 'Rudylenners',
                title: 'Rudy Lenners',
                subtitle: 'Rock',
                type : 'basic',
                link  : '/rudylenners'
            },
            {
                id   : 'Evyka',
                title: 'Evyka',
                subtitle: 'Electro-instrumental épique',
                type : 'basic',
                link  : '/evyka'
            }
        ]
    },
    {
        id   : 'registration',
        title: 'Inscrire son groupe',
        icon    : 'heroicons_solid:pencil',
        type : 'basic',
        link  : '/registration'
    },
    {
        id   : 'order',
        title: 'Commander',
        icon    : 'heroicons_solid:shopping-cart',
        type : 'basic',
        link  : '/order'
    },
    {
        id   : 'Contact',
        title: 'Contact',
        icon    : 'heroicons_solid:mail',
        type : 'basic',
        link  : '/contact'
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_solid:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_solid:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_solid:chart-pie',
        link : '/example'
    }
];
