import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { ConceptComponent } from './pages/concept/concept.component';
import { GroupSkalComponent } from './pages/group-skal/group-skal.component';
import { ContactComponent } from './pages/contact/contact.component';
import { GroupBarakaComponent } from './pages/group-baraka/group-baraka.component';
import { GroupGazigannComponent } from './pages/group-gazigann/group-gazigann.component';
import { GroupGbbComponent } from './pages/group-gbb/group-gbb.component';
import { GroupBugulnozComponent } from './pages/group-bugulnoz/group-bugulnoz.component';
import { GroupYvesPaquetComponent } from './pages/group-yves-paquet/group-yves-paquet.component';
import { GroupShaarghotComponent } from './pages/group-shaarghot/group-shaarghot.component';
import { GroupThomasFrankHopperComponent } from './pages/group-thomas-frank-hopper/group-thomas-frank-hopper.component';
import { GroupVersatileComponent } from './pages/group-versatile/group-versatile.component';
import { GroupKidNoizeComponent } from './pages/group-kid-noize/group-kid-noize.component';
import { GroupLaJungleComponent } from './pages/group-la-jungle/group-la-jungle.component';
import { GroupSmokebombComponent } from './pages/group-smokebomb/group-smokebomb.component';
import { GroupChumpComponent } from './pages/group-chump/group-chump.component';
import { GroupFleddyMelculyComponent } from './pages/group-fleddy-melculy/group-fleddy-melculy.component';
import { GroupRudyLennersComponent } from './pages/group-rudy-lenners/group-rudy-lenners.component';
import { GroupEvykaComponent } from './pages/group-evyka/group-evyka.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { OrderComponent } from './pages/order/order.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { LandingComponent } from './pages/landing/landing.component';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
    {
        path       : '',
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: '', pathMatch : 'full', redirectTo: '/landing'},
            {
                path: 'concept',
                component: ConceptComponent,
            },
            {
                path: 'groups',
                component: GroupsComponent,
            },
            {
                path: 'skal',
                component: GroupSkalComponent,
            },
            {
                path: 'gazigann',
                component: GroupGazigannComponent,
            },
            {
                path: 'baraka',
                component: GroupBarakaComponent,
            },
            {
                path: 'gbb',
                component: GroupGbbComponent,
            },
            {
                path: 'bugulnoz',
                component: GroupBugulnozComponent,
            },
            {
                path: 'yvespaquet',
                component: GroupYvesPaquetComponent,
            },
            {
                path: 'shaarghot',
                component: GroupShaarghotComponent,
            },
            {
                path: 'thomasfrankhopper',
                component: GroupThomasFrankHopperComponent,
            },
            {
                path: 'versatile',
                component: GroupVersatileComponent,
            },
            {
                path: 'kidnoize',
                component: GroupKidNoizeComponent,
            },
            {
                path: 'lajungle',
                component: GroupLaJungleComponent,
            },
            {
                path: 'smokebomb',
                component: GroupSmokebombComponent,
            },
            {
                path: 'chump',
                component: GroupChumpComponent,
            },
            {
                path: 'fleddymelculy',
                component: GroupFleddyMelculyComponent,
            },
            {
                path: 'rudylenners',
                component: GroupRudyLennersComponent,
            },
            {
                path: 'evyka',
                component: GroupEvykaComponent,
            },
            {
                path: 'registration',
                component: RegistrationComponent,
            },
            {
                path: 'order',
                component: OrderComponent,
            },
            {
                path: 'contact',
                component: ContactComponent,
            },
            
        ]
    },
    {
        path: 'landing',
        component: LandingComponent,
    },
];
